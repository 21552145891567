import { noOp } from "@flashparking-inc/ux-lib-react";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from "react";

export interface AppStatusContext {
  /** Portal is currently unable to retrieve data from the Offline API */
  isOffline: boolean;
  /** Set the current portal offline state */
  setIsOffline: Dispatch<SetStateAction<boolean>>;
}

export const AppStatusContext = createContext<AppStatusContext>({
  isOffline: false,
  setIsOffline: noOp
});

export function AppStatusContextProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;

  const [isOffline, setIsOffline] = useState(false);

  return (
    <AppStatusContext.Provider value={{ isOffline, setIsOffline }}>
      {children}
    </AppStatusContext.Provider>
  );
}
