import { noOp } from "@flashparking-inc/ux-lib-react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from "react";

import { ApiClient } from "src/api/ApiClient";
import { GoogleApiResponseGetUserInfo } from "src/api/services/google/GoogleApi";
import { useGoogleApiGetUserInfo } from "src/api/services/google/useGoogleApi";
import { flashOfflineApi, googleApi } from "src/app/singletons";

import { useAppStatus } from "../contexts/AppStatusContext/useAppStatus";
import { useDefaultErrorHandler } from "../errors/useDefaultErrorHandler";

const TOKEN_SCOPES =
  "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/admin.directory.group.member.readonly";

export interface AuthState {
  /** Currently authenticating user */
  isAuthenticating: boolean;
  /** User has successfully been authenticated */
  isAuthenticated: boolean;
  /** Current user data returned from Google */
  currentUser?: GoogleApiResponseGetUserInfo | null;
  /** Currently fetching user data from Google */
  isLoadingCurrentUser: boolean;
  /** User is authorized to use the offline portal app */
  isAuthorized: boolean;
  /** Currently checking if user is authorized to use the offline portal app */
  isAuthorizing: boolean;
  /** Logs the user into the offline portal */
  logIn: () => void;
  /** Logs the user out of the offline portal */
  logOut: () => void;
}

const initialState: AuthState = {
  isAuthenticating: false,
  isAuthenticated: false,
  isLoadingCurrentUser: false,
  isAuthorizing: false,
  isAuthorized: false,
  logIn: noOp,
  logOut: noOp
};

export const AuthContext = createContext<AuthState>(initialState);

export function AuthContextProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;

  const queryClient = useQueryClient();
  const handleError = useDefaultErrorHandler();
  const { setIsOffline } = useAppStatus();

  const hasMounted = useRef(false);
  const [isAuthenticating, setIsAuthenticating] = useState(initialState.isAuthenticating);
  const [isAuthenticated, setIsAuthenticated] = useState(initialState.isAuthenticated);
  const [isAuthorizing, setIsAuthorizing] = useState(initialState.isAuthorizing);
  const [isAuthorized, setIsAuthorized] = useState(initialState.isAuthorized);

  const { data: userInfo, isLoading: isLoadingUserInfo } = useGoogleApiGetUserInfo();

  /**
   * - Logs user in using Google OAuth
   * - Checks if they are authorized to use Flash Offline API
   */
  const logInWithGoogle = useGoogleLogin({
    /**
     * Using wildcard for `hd` allows for multiple Google Cloud organization accounts,
     *   .e.g. flashparking.com, arrive.com, etc.
     *
     * https://developers.google.com/identity/openid-connect/openid-connect#authenticationuriparameters
     */
    hosted_domain: "*",
    scope: TOKEN_SCOPES,

    onError({ error }) {
      setIsAuthenticating(false);
      handleError({ title: "Login Error", children: error });
    },

    // Triggered when user exits the login screen before finishing
    onNonOAuthError(error) {
      setIsAuthenticating(false);
      handleError({ title: "Login Error", children: error.type });
    },

    onSuccess(tokenResponse) {
      const { access_token } = tokenResponse;
      if (!access_token) {
        setIsAuthenticating(false);
        handleError({ title: "No access token in response from Google" });
        return;
      }

      validateGoogleAccessToken(access_token);
    }
  });

  const authorizeForFlashOfflineApi = useCallback(
    (googleAccessToken: string) => {
      setIsAuthorizing(true);
      flashOfflineApi
        .getApiKey(googleAccessToken)
        .then((getApiKeyResponse) => {
          setIsAuthorized(getApiKeyResponse);
        })
        .catch((err) => {
          // TODO: improve error handling
          setIsAuthorized(false);
          handleError({ title: "Flash Offline API authorization error" });

          if (ApiClient.isFailedToFetchError(err)) {
            setIsOffline(true);
          }
        })
        .finally(() => {
          setIsAuthorizing(false);
        });
    },
    [handleError, setIsOffline]
  );

  const validateGoogleAccessToken = useCallback(
    (accessToken: string) => {
      googleApi
        .validateAccessToken(accessToken)
        .then(() => {
          googleApi.setAccessToken(accessToken);
          setIsAuthenticated(true);
          authorizeForFlashOfflineApi(accessToken);
        })
        .catch(() => {
          setIsAuthenticated(false);
          handleError({ title: "Failed Google access token validation" });
          googleApi.logOut();
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    },
    [authorizeForFlashOfflineApi, handleError]
  );

  useEffect(
    function tryAuthenticationWithStoredToken() {
      // prevent duplicate token validation attempts
      /* v8 ignore next 3 -- not worth testing */
      if (hasMounted.current) {
        return;
      }

      const storedToken = googleApi.getStoredAccessToken();
      if (storedToken) {
        setIsAuthenticating(true);
        validateGoogleAccessToken(storedToken);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should only run on initial render
    []
  );

  useEffect(function setMountedToTrue() {
    hasMounted.current = true;
  }, []);

  function logIn() {
    setIsAuthenticating(true);
    logInWithGoogle();
  }

  /** Logs user out of application */
  function logOut() {
    googleLogout();
    setIsAuthenticated(false);
    setIsAuthorized(false);
    googleApi.logOut();
    flashOfflineApi.logOut();
    queryClient.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        isLoadingCurrentUser: isAuthenticated && isLoadingUserInfo,
        currentUser: userInfo,
        isAuthorizing,
        isAuthorized,
        logIn,
        logOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
