import {
  FlashButton,
  FlashCard,
  FlashSpinner,
  GoogleG,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { PropsWithChildren } from "react";

import FlashParcsGlobalPortalSvg from "src/assets/images/flash-parcs-offline-portal.svg?react";
import { useAuth } from "src/lib/auth/useAuth";

import { version } from "../../../../../package.json";

import "./LoginScreen.scss";

export default function LoginScreen() {
  const { logIn, isAuthenticating, isAuthenticated, isLoadingCurrentUser, isAuthorizing } =
    useAuth();

  return (
    <div id="login-screen-wrapper" className="vh-100 d-flex flex-column">
      <div className="flex-grow-1 d-flex">
        <div id="login-screen-desktop-filler" className="w-50 d-none d-lg-block" />
        <div
          id="login-screen-main-panel"
          className="flex-grow-1 d-flex justify-content-center align-items-sm-center py-flash-300"
        >
          <ContentWrapper>
            <div className="text-center">
              <div className="mb-flash-500">
                <FlashParcsGlobalPortalSvg />
              </div>
              <FlashButton
                id="login-btn"
                color="secondary"
                onClick={logIn}
                disabled={isAuthenticating || isAuthenticated}
                isLoading={isAuthenticating}
                LeadingIcon={GoogleG}
              >
                <div className="mb-nflash-050">Sign in with Google</div>
              </FlashButton>
              {isAuthenticated && (isLoadingCurrentUser || isAuthorizing) && (
                <div className="d-flex flex-column align-items-center">
                  <FlashSpinner size="3rem" />
                  <p>{isLoadingCurrentUser ? "Loading User Data" : "Authorizing"}</p>
                </div>
              )}
            </div>
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
}

/** Wraps content in a card on desktop, removes the card on mobile */
function ContentWrapper(props: PropsWithChildren<unknown>) {
  const { children = null } = props;
  const { windowIsMobile } = useWindowSize();

  const PaddedDiv = (
    <div className="px-flash-300 py-flash-500">
      <div className="d-flex flex-column">
        {/* <FlashCentralSvg /> */}
        <main className="my-flash-500">{children}</main>
        <div className="text-center">
          <div className="flash-font-body-s text-tertiary">
            Version: <code className="text-tertiary">{version}</code>
          </div>
        </div>
      </div>
    </div>
  );

  /* v8 ignore start -- This is just a visual flourish, not worth testing */
  if (windowIsMobile) {
    return <div className="w-100">{PaddedDiv}</div>;
  }

  return <FlashCard elevation="base">{PaddedDiv}</FlashCard>;
  /* v8 ignore end */
}
