import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router";

import { SearchWithPagination, SearchWithStatus, Status } from "../types";

export type LocationByIdSearch = SearchWithStatus & SearchWithPagination & { name?: string };

export const Route = createFileRoute(
  "/offline-status/regions/$regionId/localities/$localityId/locations/$locationId"
)({
  validateSearch(search: Record<string, unknown>): LocationByIdSearch {
    return {
      status: (search?.status as Status) || "all",
      pageSize: search.pageSize as number,
      currentPage: search.currentPage as number,
      name: search.name as string
    };
  },

  beforeLoad({ params }) {
    return { pageName: params.locationId };
  },

  component: lazyRouteComponent(
    () => import("src/app/pages/offlineStatus/locationDevices/OfflineStatusLocationDevicesPage")
  )
});
