import { HomeFilled } from "@flashparking-inc/ux-lib-react";
import { Fragment } from "react";

import { Route as OfflineStatusRegionsRoute } from "src/app/routes/offline-status.regions.index";

import SiteInfo from "./SiteInfo";
import { NavbarLink, NavSectionWrapper } from "./utils";

export default function NavMenuCommon() {
  return (
    <Fragment>
      <NavSectionWrapper>
        <NavbarLink LeadingIcon={HomeFilled} to={OfflineStatusRegionsRoute.id}>
          Offline status
        </NavbarLink>
      </NavSectionWrapper>
      <div className="flex-fill" />
      <hr className="my-flash-100" />
      <NavSectionWrapper>
        <SiteInfo />
      </NavSectionWrapper>
    </Fragment>
  );
}
