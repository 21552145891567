import { RouterProvider } from "@tanstack/react-router";

import { useAuth } from "src/lib/auth/useAuth";

import { router } from "../singletons";

export default function AppContent() {
  const auth = useAuth();

  return <RouterProvider router={router} context={{ auth }} />;
}
