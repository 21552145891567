import { FlashButton, FlashButtonProps, Logout } from "@flashparking-inc/ux-lib-react";

import { useAuth } from "../useAuth";

export default function LogoutButton(
  props: Pick<Partial<FlashButtonProps>, "id" | "isBlock" | "align">
) {
  const { logOut } = useAuth();

  return (
    <FlashButton id="logout-btn" onClick={logOut} LeadingIcon={Logout} color="secondary" {...props}>
      Log Out
    </FlashButton>
  );
}
