import { HTTP_STATUS_CODES } from "src/api/constants";

import { ApiError } from "../../../errors/ApiError";

import { REACT_QUERY_DEFAULT_MAX_FAILURES } from "./constants";

/**
 * Skips retrying a request if it failed with a `403` error code
 *
 * @returns Function that can be used as the `retry` function for React Query options
 */
export function skipRetryIfForbidden(maxFailures = REACT_QUERY_DEFAULT_MAX_FAILURES) {
  return function (failureCount: number, error: ApiError) {
    if (failureCount >= maxFailures) {
      return false;
    }

    return error.response.status !== HTTP_STATUS_CODES.FORBIDDEN;
  };
}
