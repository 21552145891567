import { noOp } from "@flashparking-inc/ux-lib-react";
import { LinkProps, RouteIds, useRouterState } from "@tanstack/react-router";
import { isEqual } from "lodash-es";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { routeTree } from "src/app/routeTree.gen";
import { getMatchesWithPageName } from "src/lib/vendor/tanstack/reactRouter/utils";

export const BreadcrumbsContext = createContext<BreadcrumbsContextState>({
  breadcrumbs: [],
  updateBreadcrumbs: noOp
});

export function BreadcrumbsContextProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;

  const routerState = useRouterState();
  const [hasUpdatedBreadcrumbsToMatchRouterState, setHasUpdatedBreadcrumbsToMatchRouterState] =
    useState(false);

  /** Get breadcrumbs data based on current route */
  const breadcrumbsFromRouterState: BreadcrumbsContextState["breadcrumbs"] = useMemo(() => {
    setHasUpdatedBreadcrumbsToMatchRouterState(false);
    const matches = getMatchesWithPageName({ matches: routerState.matches });
    return matches.map((m) => ({
      text: m.context.pageName,
      to: m.routeId,
      params: m.params
    }));
  }, [routerState.matches]);

  /** Keep track of custom breadcrumbs state to allow ad-hoc manipulation */
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsContextState["breadcrumbs"]>([]);

  /** Keep track of matches by ID to prevent override of custom breadcrumbs */
  const prevMatches = useRef<string[]>([]);
  useEffect(
    function updateCustomBreadcrumbsToMatchRouterState() {
      const routerStateMatchIds = getMatchIds(routerState.matches);
      if (!isEqual(routerStateMatchIds, prevMatches.current)) {
        setBreadcrumbs(breadcrumbsFromRouterState);
        prevMatches.current = routerStateMatchIds;
      }
      setHasUpdatedBreadcrumbsToMatchRouterState(true);
    },
    [routerState.matches, breadcrumbsFromRouterState]
  );

  /** Callback to invoke to edit breadcrumbs from within rendered components */
  const updateBreadcrumbs = useCallback(
    (...args: Parameters<BreadcrumbsContextState["updateBreadcrumbs"]>) => {
      // Wait for internal effect to update breadcrumbs to match router state
      if (!hasUpdatedBreadcrumbsToMatchRouterState) {
        return;
      }

      setBreadcrumbs((prev) => {
        const [breadcrumbs] = args;
        const newBreadcrumbs = [...prev];

        Object.entries(breadcrumbs).forEach(([to, breadcrumb]) => {
          const matchingIndex = newBreadcrumbs.findIndex((bc) => bc.to === to);
          if (newBreadcrumbs[matchingIndex]) {
            newBreadcrumbs[matchingIndex] = { ...newBreadcrumbs[matchingIndex], ...breadcrumb };
          }
        });

        /* v8 ignore start -- prevents infinite rerender, but writing test for this is more trouble than it's worth */
        if (!isEqual(newBreadcrumbs, prev)) {
          return newBreadcrumbs;
        }

        return prev;
        /* v8 ignore end */
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
         Need to update the memoized callback anytime the breadcrumbsFromRouterState
         changes, so that it can rerun and override the default breadcrumbs anytime
         they change */
    [hasUpdatedBreadcrumbsToMatchRouterState, breadcrumbsFromRouterState]
  );

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

export interface BreadcrumbsContextState {
  breadcrumbs: Breadcrumb[];
  updateBreadcrumbs: (
    breadcrumbs: Partial<
      Record<RouteIds<typeof routeTree>, Partial<Pick<Breadcrumb, "text" | "hideInPageHeader">>>
    >
  ) => void;
}

export interface Breadcrumb {
  /** Text to render for the breadcrumb */
  text: string;
  /** Path the breadcrumb should lead to */
  to: RouteIds<typeof routeTree>;
  /** Params for the link the breadcrumb should lead to */
  params: LinkProps["params"];
  /** Whether or not to hide this section of the breadcrumbs in the page main header */
  hideInPageHeader?: boolean;
}

function getMatchIds(matches: { id: string }[]) {
  return matches.map((m) => m.id);
}
