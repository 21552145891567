import { FlashInfoMessageType, FlashToastProps, useToasts } from "@flashparking-inc/ux-lib-react";
import { useCallback } from "react";

export function useDefaultErrorHandler() {
  const { addToast } = useToasts();

  return useCallback(
    (toastProps: Partial<FlashToastProps> = {}) => {
      addToast({ props: { type: FlashInfoMessageType.critical, title: "Error", ...toastProps } });
    },
    [addToast]
  );
}
