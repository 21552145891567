import { Navigate, Outlet, createFileRoute, useRouterState } from "@tanstack/react-router";

import { Route as OfflineStatusRegionsRoute } from "src/app/routes/offline-status.regions.index";
import { routeMatches } from "src/lib/vendor/tanstack/reactRouter/utils";

export const Route = createFileRoute("/offline-status")({
  beforeLoad() {
    return { pageName: "Offline status" };
  },

  component: RouteComponent
});

export function RouteComponent() {
  const { matches } = useRouterState();

  /**
   * Redirects to the "all regions" route by default
   *
   * This route does not provide any content of its own, but is used to allow for
   *   nesting under the `/offline-status/` path, providing an applicable match
   *   for breadcrumbs.
   */
  if (routeMatches(matches, "/offline-status")) {
    return <Navigate to={OfflineStatusRegionsRoute.id} replace />;
  }

  return <Outlet />;
}
