import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router";

import { SearchWithPagination, SearchWithStatus, Status } from "../types";

export type RegionByIdSearch = SearchWithStatus & SearchWithPagination & { name?: string };

export const Route = createFileRoute("/offline-status/regions/$regionId")({
  validateSearch(search: Record<string, unknown>): RegionByIdSearch {
    return {
      status: (search?.status as Status) || "all",
      pageSize: search.pageSize as number,
      currentPage: search.currentPage as number,
      name: search.name as string
    };
  },

  beforeLoad({ params }) {
    return { pageName: params.regionId };
  },

  component: lazyRouteComponent(
    () => import("src/app/pages/offlineStatus/regionLocalities/OfflineStatusRegionLocalitiesPage")
  )
});
