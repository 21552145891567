import { RouteIds, RouterState } from "@tanstack/react-router";

import { routeTree } from "src/app/routeTree.gen";

export function routeMatches(matches: RouterState["matches"], id: RouteIds<typeof routeTree>) {
  if (!matches.length) {
    return false;
  }

  return matches[matches.length - 1].routeId === id;
}

/** Returns array of matches for current page that have a `context.pageName` */
export function getMatchesWithPageName(routerState: {
  matches: PageMatch[];
}): PageMatchWithPageName[] {
  return routerState.matches.filter(isContextWithPageName);
}

function isContextWithPageName(m: { context: PageMatch["context"] }): m is PageMatchWithPageName {
  return !!m.context.pageName?.length;
}

export type PageMatch = RouterState<typeof routeTree>["matches"][number];
type PageMatchWithPageName = Omit<PageMatch, "context"> & {
  context: Omit<PageMatch["context"], "pageName"> & { pageName: string };
};
