import LogoutButton from "src/lib/auth/components/LogoutButton";
import LoginScreen from "src/lib/auth/screens/LoginScreen/LoginScreen";
import { useAuth } from "src/lib/auth/useAuth";
import { useAppStatus } from "src/lib/contexts/AppStatusContext/useAppStatus";

import { BreadcrumbsContextProvider } from "./Breadcrumbs/BreadcrumbsContext";
import LinksToPortalOnOtherProviders from "./LinksToPortalOnOtherProviders";
import SecureContent from "./SecureContent";
import UnauthorizedWarning from "./UnauthorizedWarning";

export default function RootComponent() {
  const { isOffline } = useAppStatus();
  const { isAuthenticated, isAuthorizing, isAuthorized, isLoadingCurrentUser } = useAuth();

  if (isOffline) {
    return <LinksToPortalOnOtherProviders />;
  }

  if (!isAuthenticated || isLoadingCurrentUser || isAuthorizing) {
    return <LoginScreen />;
  }

  if (isAuthorized) {
    return (
      <BreadcrumbsContextProvider>
        <SecureContent />
      </BreadcrumbsContextProvider>
    );
  }

  return (
    <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center gap-flash-300">
        <div className="mb-flash-300">{/* <FlashParcsGlobalPortalSvg /> */}</div>
        <LogoutButton />
        <UnauthorizedWarning />
      </div>
    </div>
  );
}
