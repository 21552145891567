/* v8 ignore start -- Don't need coverage on this file, it just wraps the app with "global" context */
import { FlashToasts } from "@flashparking-inc/ux-lib-react";
import { FlashAppContextProvider } from "@flashparking-inc/ux-lib-react/dist/context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";

import { AuthContextProvider } from "src/lib/auth/AuthContext";
import { AppStatusContextProvider } from "src/lib/contexts/AppStatusContext/AppStatusContext";

import AppContent from "./layout/AppContent";
import { queryClient, toastMaster } from "./singletons";

export default function App() {
  return (
    <FlashAppContextProvider toastProps={{ toastMaster }}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
          <AppStatusContextProvider>
            <AuthContextProvider>
              <AppContent />
              <FlashToasts />
            </AuthContextProvider>
          </AppStatusContextProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </FlashAppContextProvider>
  );
}
/* v8 ignore stop */
