/* v8 ignore start -- Don't need coverage on this file, it just creates the app root */
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "@flashparking-inc/ux-lib-react/styles/flash.scss";

import App from "./app/App.tsx";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
/* v8 ignore end */
