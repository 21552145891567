import { Navigate, createFileRoute } from "@tanstack/react-router";

import { Route as OfflineStatusRootRoute } from "src/app/routes/offline-status";

export const Route = createFileRoute("/")({
  component() {
    // default redirect
    return <Navigate to={OfflineStatusRootRoute.id} replace />;
  }
});
