import { rootRouteWithContext } from "@tanstack/react-router";

import { useAuth } from "src/lib/auth/useAuth";

import RootComponent from "../layout/RootComponent";

export interface RouterContext {
  /** Auth context state */
  auth: ReturnType<typeof useAuth>;
  /** Page name for the current route */
  pageName: string;
}

export const Route = rootRouteWithContext<RouterContext>()({
  component: RootComponent
});
