import { version } from "package.json";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

export default function SiteInfo() {
  return (
    <div className="d-flex flex-column gap-flash-050 p-flash-200">
      <span className="flash-font-body-s">
        Version: <code className="text-primary">{version}</code>
      </span>
      <div className="d-flex flex-column gap-flash-100">
        <SiteInfoLink href="https://www.flashparking.com/terms-of-use/">Terms of Use</SiteInfoLink>
        <SiteInfoLink href="https://www.flashparking.com/privacy-policy/">
          Privacy Policy
        </SiteInfoLink>
        <span className="flash-font-body-xs">
          &copy; {new Date().getFullYear()} Flash All Rights Reserved
        </span>
      </div>
    </div>
  );
}

function SiteInfoLink(
  props: Pick<
    DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    "href" | "children"
  >
) {
  return (
    <a
      className="btn btn-link_subtle text-start flash-font-body-xs"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
}
