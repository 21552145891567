import { FlashButton, FlashButtonProps, cssSelectorSafeId } from "@flashparking-inc/ux-lib-react";
import { useLinkProps, useRouterState } from "@tanstack/react-router";
import { MouseEventHandler, PropsWithChildren } from "react";

export function NavSectionHeader(props: PropsWithChildren<unknown>) {
  return (
    <header className="m-flash-200 mb-flash-050 flash-font-heading-xs text-tertiary">
      {props.children}
    </header>
  );
}

export function NavSectionWrapper(props: PropsWithChildren<unknown>) {
  return <div className="px-flash-050">{props.children}</div>;
}

export function NavbarLink(props: Omit<FlashButtonProps, "id"> & { to: string }) {
  const { to, ...rest } = props;
  const { location } = useRouterState();
  const linkProps = useLinkProps({
    to,
    params: true
  });
  const currentLocationWithinDestination = location.pathname.startsWith(to.replace(/\/$/, ""));

  return (
    <FlashButton
      id={cssSelectorSafeId(`nav-link-${to}`)}
      isBlock
      align="start"
      tightRadius
      color="tertiary_subtle"
      isSelected={currentLocationWithinDestination}
      onClick={linkProps.onClick as MouseEventHandler<Element>}
      {...rest}
    />
  );
}
