import { Navigate, Outlet, createFileRoute, useRouterState } from "@tanstack/react-router";

import { Route as OfflineStatusLocalityByIdRoute } from "src/app/routes/offline-status.regions.$regionId_.localities.$localityId";
import { routeMatches } from "src/lib/vendor/tanstack/reactRouter/utils";

export const Route = createFileRoute(
  "/offline-status/regions/$regionId/localities/$localityId/locations"
)({
  beforeLoad({ params }) {
    return { pageName: params.localityId };
  },

  component: RouteComponent
});

export function RouteComponent() {
  const { matches } = useRouterState();

  /**
   * Redirect to corresponding locality route if exact match
   *
   * This route is to provide an "non-nested" route for locations by ID, but does
   *   not provide any content of its own, but allows for nesting under the
   *   `/offline-status/regions/$regionId/localities/$localityId}/locations` path,
   *   providing an applicable match for breadcrumbs.
   *
   * Non-nested routes docs: https://tanstack.com/router/latest/docs/framework/react/guide/route-trees#non-nested-routes
   */
  if (routeMatches(matches, "/offline-status/regions/$regionId/localities/$localityId/locations")) {
    return <Navigate to={OfflineStatusLocalityByIdRoute.id} replace params />;
  }

  return <Outlet />;
}
