import { useQuery } from "@tanstack/react-query";

import { googleApi } from "src/app/singletons";
import { skipRetryIfForbidden } from "src/lib/vendor/tanstack/reactQuery/retryUtils";

export function useGoogleApiGetUserInfo() {
  return useQuery({
    queryKey: googleApiQueryKeys.getUserInfo(googleApi.hasAccessToken),
    async queryFn() {
      if (!googleApi.hasAccessToken) {
        return null;
      }

      return await googleApi.getUserInfo();
    },
    retry: skipRetryIfForbidden()
  });
}

/** Hierarchical query keys used for `GoogleApi` */
const googleApiQueryKeys = {
  /** Targets **all** `GoogleApi` requests */
  all() {
    return ["GoogleApi"];
  },

  /** Targets all `GoogleApi` requests with matching common params */
  withCommonParams(isReady: boolean) {
    return [...googleApiQueryKeys.all(), { isReady }];
  },

  /** Targets `GoogleApi.getUserInfo` requests */
  getUserInfo(isReady: boolean) {
    return [...googleApiQueryKeys.withCommonParams(isReady), "getUserInfo"];
  }
};
