import { FlashSideNav, FlashSideNavCloseButton, useCollapse } from "@flashparking-inc/ux-lib-react";

import NavMenuCommon from "./NavMenuCommon";
import { NavSectionWrapper } from "./utils";

interface SideNavProps {
  sideNavState: ReturnType<typeof useCollapse>;
}

export default function NavMenuDesktop(props: SideNavProps) {
  const { sideNavState } = props;

  return (
    <FlashSideNav collapse={sideNavState} className="h-100" collapseMinWidth={240}>
      <div className="h-100 overflow-scroll">
        <div className="h-100 py-flash-050 d-flex flex-column gap-flashd-013">
          {!!sideNavState.close && (
            <NavSectionWrapper>
              <FlashSideNavCloseButton close={sideNavState.close} />
            </NavSectionWrapper>
          )}
          <NavMenuCommon />
        </div>
      </div>
    </FlashSideNav>
  );
}
