import { QueryClientConfig } from "@tanstack/react-query";

import { MILLISECONDS_PER_MINUTE } from "src/lib/constants";

export const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 5 * MILLISECONDS_PER_MINUTE,
      // better to handle retry logic at more granular level
      retry: false
    }
  }
};
