import { ApiClientWithApiKey } from "src/api/ApiClientWithApiKey";

import {
  Device,
  FlashOfflineApiReqParamsForCounts,
  FlashOfflineApiReqParamsForHistory,
  Locality,
  Location,
  Region
} from "./types";

export class FlashOfflineApi extends ApiClientWithApiKey {
  constructor() {
    super(import.meta.env.VITE_OFFLINE_API_BASE_URL);
  }

  /** Used `ApiClient` currently has an API key */
  get hasApiKey() {
    return !!this.apiKey;
  }

  /** Exchanges Google access token for Offline API Key */
  async getApiKey(accessToken: string) {
    const response = await this.fetch<{ api_key: string; expires_at: number }>("/v1/login/", {
      method: "POST",
      headers: new Headers({ Authorization: `Bearer ${accessToken}` })
    });

    if (response.api_key) {
      this.setApiKey(response.api_key);
    } else {
      this.setApiKey("");
    }

    return this.hasApiKey;
  }

  v1 = {
    regions: {
      all: {
        get: async <
          IncludeCounts extends boolean | undefined,
          IncludeHistory extends boolean | undefined
        >(
          req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
        ): Promise<Region<IncludeCounts, IncludeHistory>[]> =>
          await this.fetchWithApiKey(urlForCommonReq("/v1/regions/", req)),

        setStatus: async (req: FlashOfflineApiReqV1RegionsBulkSetStatus) => {
          return await this.fetchWithApiKey(
            "/v1/regions/status/",
            {
              method: "POST",
              body: JSON.stringify(req)
            },
            { parseJson: false }
          );
        }
      },

      byId: (regionId: number) => ({
        get: async <
          IncludeCounts extends boolean | undefined,
          IncludeHistory extends boolean | undefined
        >(
          req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
        ): Promise<Region<IncludeCounts, IncludeHistory>> => {
          const data = await this.fetchWithApiKey<Region<IncludeCounts, IncludeHistory>[]>(
            urlForCommonReq(`/v1/regions/${regionId}/`, req)
          );
          return data[0];
        },

        localities: {
          get: async <
            IncludeCounts extends boolean | undefined,
            IncludeHistory extends boolean | undefined
          >(
            req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
          ): Promise<Locality<IncludeCounts, IncludeHistory>[]> =>
            await this.fetchWithApiKey(urlForCommonReq(`/v1/regions/${regionId}/localities/`, req))
        }
      })
    },
    localities: {
      all: {
        setStatus: async (req: FlashOfflineApiReqV1LocalitiesBulkSetStatus) => {
          return await this.fetchWithApiKey(
            "/v1/localities/status/",
            {
              method: "POST",
              body: JSON.stringify(req)
            },
            { parseJson: false }
          );
        }
      },

      byId: (localityId: number) => ({
        get: async <
          IncludeCounts extends boolean | undefined,
          IncludeHistory extends boolean | undefined
        >(
          req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
        ): Promise<Locality<IncludeCounts, IncludeHistory>> => {
          const data = await this.fetchWithApiKey<Locality<IncludeCounts, IncludeHistory>[]>(
            urlForCommonReq(`/v1/localities/${localityId}/`, req)
          );
          return data[0];
        },

        locations: {
          get: async <
            IncludeCounts extends boolean | undefined,
            IncludeHistory extends boolean | undefined
          >(
            req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
          ): Promise<Location<IncludeCounts, IncludeHistory>[]> =>
            await this.fetchWithApiKey(
              urlForCommonReq(`/v1/localities/${localityId}/locations/`, req)
            )
        }
      })
    },
    locations: {
      all: {
        setStatus: async (req: FlashOfflineApiReqV1LocationsBulkSetStatus) =>
          await this.fetchWithApiKey(
            "/v1/locations/status/",
            {
              method: "POST",
              body: JSON.stringify(req)
            },
            { parseJson: false }
          )
      },

      byId: (locationId: string) => ({
        get: async <
          IncludeCounts extends boolean | undefined,
          IncludeHistory extends boolean | undefined
        >(
          req?: FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>
        ): Promise<Location<IncludeCounts, IncludeHistory>> => {
          const data = await this.fetchWithApiKey<Location<IncludeCounts, IncludeHistory>[]>(
            urlForCommonReq(`/v1/locations/${locationId}/`, req)
          );
          return data[0];
        },

        devices: {
          get: async <IncludeHistory extends boolean | undefined>(
            req?: FlashOfflineApiReqV1LocationDevicesGet<IncludeHistory>
          ): Promise<Device<IncludeHistory>[]> =>
            await this.fetchWithApiKey(urlForCommonReq(`/v1/locations/${locationId}/devices/`, req))
        }
      })
    },
    devices: {
      all: {
        setStatus: async (req: FlashOfflineApiReqV1DevicesBulkSetStatus) => {
          return await this.fetchWithApiKey(
            "/v1/devices/status/",
            {
              method: "POST",
              body: JSON.stringify(req)
            },
            { parseJson: false }
          );
        }
      }
    }
  };
}

/** Builds URL with query string parameters */
function urlForCommonReq<
  Req extends FlashOfflineApiReqV1CommonGet<IncludeCounts, IncludeHistory>,
  IncludeCounts extends boolean | undefined,
  IncludeHistory extends boolean | undefined
>(relativeUrl: string, req?: Req): string {
  const search = new URLSearchParams();
  if (typeof req?.include_counts === "boolean") {
    search.set("include_counts", JSON.stringify(req.include_counts));
  }

  if (typeof req?.include_history === "boolean") {
    search.set("include_history", JSON.stringify(req.include_history));

    if (req.include_history && (req as FlashOfflineApiReqV1CommonGet<boolean, true>).history_size) {
      search.set(
        "history_size",
        (req as FlashOfflineApiReqV1CommonGet<boolean, true>).history_size!.toString()
      );
    }
  }

  if (Array.from(search.entries()).length) {
    return `${relativeUrl}?${search.toString()}`;
  }

  return relativeUrl;
}

export type FlashOfflineApiReqV1CommonGet<
  IncludeCounts extends boolean | undefined,
  IncludeHistory extends boolean | undefined
> = FlashOfflineApiReqParamsForCounts<IncludeCounts> &
  FlashOfflineApiReqParamsForHistory<IncludeHistory>;

export interface FlashOfflineApiReqV1RegionsBulkSetStatus {
  region_ids: number[];
  is_online: boolean;
}

export interface FlashOfflineApiReqV1LocalitiesBulkSetStatus {
  locality_ids: number[];
  is_online: boolean;
}

export interface FlashOfflineApiReqV1LocationsBulkSetStatus {
  location_ids: string[];
  is_online: boolean;
}

export interface FlashOfflineApiReqV1DevicesBulkSetStatus {
  device_ids: string[];
  is_online: boolean;
}

export type FlashOfflineApiReqV1LocationDevicesGet<IncludeHistory extends boolean | undefined> =
  FlashOfflineApiReqParamsForHistory<IncludeHistory>;
