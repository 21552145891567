import {
  FlashButton,
  FlashModal,
  FlashPopover,
  PersonFilled,
  sass,
  useFlashModal,
  useFlashPopover,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { useRef } from "react";

import LogoutButton from "src/lib/auth/components/LogoutButton";
import { useAuth } from "src/lib/auth/useAuth";

const TARGET_ID = "navbar-content-user-profile-btn";

export default function NavbarContentUserProfileButton() {
  const { windowIsMobile } = useWindowSize();
  const { currentUser } = useAuth();

  const containerRef = useRef<HTMLDivElement>(null);
  const modalState = useFlashModal();
  const popoverState = useFlashPopover();

  const helloMessage = `Hello${currentUser ? ` ${currentUser.name}` : ""}`;
  const PopupContent = <LogoutButton id="navbar-content-logout-btn" isBlock align="start" />;

  return (
    <div ref={containerRef}>
      <FlashButton
        id={TARGET_ID}
        onClick={(e) => {
          e.preventDefault();
          windowIsMobile ? modalState.open() : popoverState.open();
        }}
        LeadingIcon={PersonFilled}
        color="icon_only_default"
        removePadding
        iconFontSize={sass.typography.flash.semantic.font.heading.xl.size}
      />
      <FlashPopover
        {...popoverState}
        target={TARGET_ID}
        container={containerRef.current ?? undefined}
        placement="bottom-end"
        className="navbar-content-user-profile-popover"
      >
        <div className="d-flex flex-column p-flash-200 gap-flash-200">
          <div className="">{helloMessage}</div>
          {PopupContent}
        </div>
      </FlashPopover>
      <FlashModal
        modalId="navbar-content-user-profile"
        {...modalState}
        hideFooter
        Title={helloMessage}
      >
        {PopupContent}
      </FlashModal>
    </div>
  );
}
