import { Navigate, Outlet, createFileRoute, useRouterState } from "@tanstack/react-router";

import { Route as OfflineStatusRegionByIdRoute } from "src/app/routes/offline-status.regions.$regionId";
import { routeMatches } from "src/lib/vendor/tanstack/reactRouter/utils";

export const Route = createFileRoute("/offline-status/regions/$regionId/localities")({
  beforeLoad({ params }) {
    return { pageName: params.regionId };
  },

  component: RouteComponent
});

export function RouteComponent() {
  const { matches } = useRouterState();

  /**
   * Redirect to corresponding region route if exact match
   *
   * This route is to provide an "non-nested" route for localities by ID, but does
   *   not provide any content of its own, but allows for nesting under the
   *   `/offline-status/regions/$regionId/localities/` path, providing an applicable
   *   match for breadcrumbs.
   *
   * Non-nested routes docs: https://tanstack.com/router/latest/docs/framework/react/guide/route-trees#non-nested-routes
   */
  if (routeMatches(matches, "/offline-status/regions/$regionId/localities")) {
    return <Navigate to={OfflineStatusRegionByIdRoute.id} replace params />;
  }

  return <Outlet />;
}
