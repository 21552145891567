import { ApiError } from "src/lib/errors/ApiError";

/** Reusable client for interacting with HTTP APIs */
export class ApiClient {
  constructor(protected baseUrl?: string) {}

  /** Wraps standard `fetch` calls with app common behavior */
  async fetch<ResponseData = unknown, ParseJson extends boolean = true>(
    url: string | URL,
    req?: RequestInit,
    options?: {
      /**
       * By default, `fetch` responses will be returned as parsed JSON, using
       *   `response.json()`. Set this to `false` to skip attempting to parse as
       *   JSON
       */
      parseJson: ParseJson;
    }
  ): Promise<ParseJson extends true ? ResponseData : Response>;
  async fetch<ResponseData = unknown, ParseJson extends boolean = true>(
    url: string | URL,
    req?: RequestInit,
    options: {
      parseJson?: ParseJson;
    } = {}
  ) {
    const { parseJson = true } = options;
    let fullUrl = typeof url === "string" ? url : url.toString();
    if (this.baseUrl && !fullUrl.startsWith("http")) {
      fullUrl = `${this.baseUrl}${fullUrl}`;
    }

    const response = await fetch(fullUrl, req);

    if (!response.ok) {
      /* v8 ignore start */
      try {
        console.error(await response.json());
      } catch {
        console.error("Unable to translate error response to JSON");
      }
      /* v8 ignore end */

      throw new ApiError(response);
    }

    if (parseJson) {
      return (await response.json()) as ResponseData;
    }

    return response;
  }

  protected initHeaders<ResponseData = unknown, ParseJson extends boolean = boolean>(
    ...args: Parameters<typeof this.fetch<ResponseData, ParseJson>>
  ) {
    let headers = args[1]?.headers;
    if (!(headers instanceof Headers)) {
      headers = new Headers(headers);
    }

    return headers;
  }

  static isFailedToFetchError(e?: unknown) {
    if (!e) {
      return false;
    }

    return (e as Error).message?.includes("Failed to fetch");
  }
}
