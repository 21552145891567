import {
  FLASH_NAVBAR_HEIGHT,
  ScrollToTopContextProvider,
  useCollapse,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { Outlet } from "@tanstack/react-router";
import classNames from "classnames";
import { useRef } from "react";

import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import NavMenuDesktop from "./Nav/NavMenuDesktop";
import Navbar from "./Nav/Navbar";

export default function SecureContent() {
  const { windowIsMobile } = useWindowSize();
  const sideNavState = useCollapse();

  const mainContentRef = useRef<HTMLDivElement>(null);

  return (
    <div data-testid="secure-content">
      <Navbar />
      <div className="d-flex vh-100 bg-secondary" style={{ paddingTop: FLASH_NAVBAR_HEIGHT }}>
        {!windowIsMobile && <NavMenuDesktop sideNavState={sideNavState} />}
        <div
          className={classNames(
            "main-content pt-flash-200 w-100 pe-sm-flash-200 ps-0 overflow-y-scroll",
            {
              "ps-sm-flash-100": !sideNavState.isOpen
            }
          )}
        >
          <Breadcrumbs />
          <ScrollToTopContextProvider containerRef={mainContentRef}>
            <div ref={mainContentRef}>
              <Outlet />
            </div>
          </ScrollToTopContextProvider>
        </div>
      </div>
    </div>
  );
}
