import { ApiClientWithAccessToken } from "src/api/ApiClientWithAccessToken";

/** Builds Google API methods with provided `ApiClient` instance */
export class GoogleApi extends ApiClientWithAccessToken {
  static ACCESS_TOKEN_STORAGE_KEY = "google_api_access_token";

  constructor() {
    super("https://www.googleapis.com");
  }

  getStoredAccessToken() {
    return localStorage.getItem(GoogleApi.ACCESS_TOKEN_STORAGE_KEY);
  }

  setAccessToken(token: string): void {
    super.setAccessToken(token);
    localStorage.setItem(GoogleApi.ACCESS_TOKEN_STORAGE_KEY, token);
  }

  logOut(): void {
    super.logOut();
    localStorage.removeItem(GoogleApi.ACCESS_TOKEN_STORAGE_KEY);
  }

  /** Verify if access token is valid */
  async validateAccessToken(accessToken: string) {
    return await this.fetch<GoogleApiResponseValidateToken>(
      `/oauth2/v2/tokeninfo?access_token=${accessToken}`
    );
  }

  /** Retrieves basic user info from Google */
  async getUserInfo() {
    return await this.fetchWithToken<GoogleApiResponseGetUserInfo>("/oauth2/v3/userinfo");
  }
}

interface GoogleApiResponseValidateToken {
  access_type: string;
  audience: string;
  email: string;
  expires_in: number;
  issued_to: string;
  scope: string;
  user_id: string;
  verified_email: boolean;
}

export interface GoogleApiResponseGetUserInfo {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  hd: string;
  locale: string;
  name: string;
  picture: string;
  sub: string;
}
