import { AuthError } from "src/lib/errors/AuthError";

import { ApiClient } from "./ApiClient";

export class ApiClientWithApiKey extends ApiClient {
  #apiKey = "";

  get apiKey() {
    return this.#apiKey;
  }

  setApiKey(apiKey: string) {
    this.#apiKey = apiKey;
  }

  get hasApiKey() {
    return !!this.#apiKey;
  }

  /** Clears API client instance's credentials */
  logOut() {
    this.setApiKey("");
  }

  /** Uses current `apiKey` for auth */
  async fetchWithApiKey<ResponseData = unknown, ParseJson extends boolean = true>(
    ...args: Parameters<typeof this.fetch<ResponseData, ParseJson>>
  ) {
    if (!this.hasApiKey) {
      throw new AuthError("Submitted request without API key");
    }

    const headers = this.initHeaders(...args);
    if (!headers.get("api-key")) {
      headers.set("api-key", this.apiKey);
    }

    return await this.fetch<ResponseData, ParseJson>(args[0], { ...args[1], headers }, args[2]);
  }
}
