import { FlashBreadcrumbs } from "@flashparking-inc/ux-lib-react";
import { Link } from "@tanstack/react-router";

import { STANDARD_CONTENT_PADDING } from "src/lib/style/classes";

import { useBreadcrumbs } from "./useBreadcrumbs";

export default function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs();
  const breadcrumbsProps = breadcrumbs.map((bc, index) => ({
    children:
      index === breadcrumbs.length - 1 ? (
        <span className="text-capitalize">{bc.text}</span>
      ) : (
        <Link to={bc.to} params={bc.params} className="text-capitalize">
          {bc.text}
        </Link>
      )
  }));

  if (!breadcrumbs.length) {
    return null;
  }

  return (
    <div className={STANDARD_CONTENT_PADDING}>
      <FlashBreadcrumbs breadcrumbs={breadcrumbsProps} />
    </div>
  );
}
