import { FlashNavbar, useWindowSize } from "@flashparking-inc/ux-lib-react";

import AppLogo from "src/assets/images/flash-parcs-offline-portal.svg";

import NavMenuMobile from "./NavMenuMobile";
import NavbarContent from "./NavbarContent/NavbarContent";

export default function Navbar() {
  const { windowIsMobile } = useWindowSize();

  return (
    <FlashNavbar
      logo={AppLogo}
      navbarChildren={<NavbarContent />}
      mobileMenuChildren={windowIsMobile ? <NavMenuMobile /> : null}
    />
  );
}
