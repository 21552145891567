import { AuthError } from "src/lib/errors/AuthError";

import { ApiClient } from "./ApiClient";

export class ApiClientWithAccessToken extends ApiClient {
  #accessToken = "";

  setAccessToken(token: string) {
    this.#accessToken = token;
  }

  get hasAccessToken() {
    return !!this.#accessToken;
  }

  /** Clears API client instance's credentials */
  logOut() {
    this.setAccessToken("");
  }

  /** Uses current `accessToken` as Bearer token for `fetch` request */
  async fetchWithToken<ResponseData = unknown>(
    ...args: Parameters<typeof this.fetch<ResponseData>>
  ) {
    if (!this.hasAccessToken) {
      throw new AuthError("Submitted request without token");
    }

    const headers = this.initHeaders(...args);
    if (!headers.get("Authorization")) {
      headers.set("Authorization", `Bearer ${this.#accessToken}`);
    }

    return await this.fetch<ResponseData>(args[0], { ...args[1], headers }, args[2]);
  }
}
