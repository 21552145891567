import { ToastMaster } from "@flashparking-inc/ux-lib-react";
import { QueryClient } from "@tanstack/react-query";
import { Router } from "@tanstack/react-router";

import { FlashOfflineApi } from "src/api/services/flashOffline/FlashOfflineApi";
import { GoogleApi } from "src/api/services/google/GoogleApi";
import { reactQueryConfig } from "src/lib/vendor/tanstack/reactQuery/config";

import { routeTree } from "./routeTree.gen";

/** Singleton instance of `ToastMaster` */
export const toastMaster = new ToastMaster();

/** Singleton instance of Tanstack `QueryClient` */
export const queryClient = new QueryClient(reactQueryConfig);

/** Singleton instance of Tanstack `Router` */
export const router = new Router({
  routeTree,
  context: { auth: undefined!, pageName: undefined! }
});

/** Google API preconfigured with required `ApiClient` singleton */
export const googleApi = new GoogleApi();

/** Offline Portal API preconfigured with required `ApiClient` singleton */
export const flashOfflineApi = new FlashOfflineApi();
