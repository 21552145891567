import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router";

import { SearchWithStatus, Status } from "../types";

export const Route = createFileRoute("/offline-status/regions/")({
  validateSearch(search: Record<string, unknown>): SearchWithStatus {
    return {
      status: (search?.status as Status) ?? "all"
    };
  },

  beforeLoad() {
    // Page name is already set by parent
    return { pageName: undefined };
  },

  component: lazyRouteComponent(
    () => import("src/app/pages/offlineStatus/allRegions/OfflineStatusAllRegionsPage")
  )
});
