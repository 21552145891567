import { FlashBanner, FlashInfoMessageType } from "@flashparking-inc/ux-lib-react";

export default function LinksToPortalOnOtherProviders() {
  const offlinePortals = [
    { href: "https://offline.parcs.arrive.com", name: "AWS" },
    { href: "https://offline.parcs.flashvalet.com", name: "Azure" },
    { href: "https://offline.parcs.flashparking.com", name: "Google Cloud" }
  ];

  const visibleLinks = offlinePortals.filter((p) => !p.href.includes(window.location.hostname));

  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
      <div className="p-flash-200">
        <FlashBanner type={FlashInfoMessageType.critical}>
          This portal is not working, please try an instance hosted on a different provider.
          <ul>
            {visibleLinks.map((p) => (
              <li key={p.href}>
                <a href={p.href}>Offline Portal on {p.name}</a>
              </li>
            ))}
          </ul>
        </FlashBanner>
      </div>
    </div>
  );
}
